import request from '@/utils/request'

export function getPickUpClaus(params) {
    return request({
        url: '/small-inteface/pickUpClaus',
        method: 'get',
        params
    })
}

export function savePickUpClaus(data) {
    return request({
        url: '/small-inteface/pickUpClaus',
        method: 'post',
        data
    })
}