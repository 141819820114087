<template>
    <div class="content">
        <div class="text">
            <tinymce v-model="pickUpClaus" :height="500" />
        </div>
        <div class="btn">
            <el-button type="primary" style="float:right;" @click="updatePickUpClaus">保存</el-button>
        </div>
    </div>
</template>

<script>
    import Tinymce from "@/components/Tinymce";
    import {
        getPickUpClaus,
        savePickUpClaus
    } from "@/api/config_center/claus_setting";

    export default {
        name: "PickUpClaus",
        components: {
            Tinymce
        },
        data() {
            return {
                pickUpClaus: ''
            }
        },
        mounted() {
            this.initial()
        },
        methods: {
            initial() {
                getPickUpClaus().then(res => {
                    if(res.data.code == 200) {
                        this.pickUpClaus = res.data.body.pickUpClaus
                    }
                })
            },
            updatePickUpClaus() {
                let data = {
                    pickUpClaus: this.pickUpClaus
                }
                savePickUpClaus(data).then(res => {
                    if(res.data.code == 200) {
                        this.initial()
                        this.$message.success('保存成功');
                    }
                })
            }
        }
    }
</script>

<style scoped>

</style>